// src/components/ProtectedRoute.js
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({ children }) => {
  const [user] = useAuthState(auth);
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const checkVerification = async () => {
      if (user) {
        await user.reload();
        setIsVerified(user.emailVerified);
      }
    };
    checkVerification();
  }, [user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isVerified === false) {
    return <Navigate to="/verify-email" />;
  }

  return children;
};

export default ProtectedRoute;
