// src/components/VerifyEmail.js
import { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      if (auth.currentUser) {
        await auth.currentUser.reload();
        if (auth.currentUser.emailVerified) {
          clearInterval(interval);
          navigate('/dashboard');
        }
      }
    }, 3000); // Check every 3 seconds

    return () => clearInterval(interval);
  }, [navigate]);

  const resendVerificationEmail = async () => {
    try {
      await auth.currentUser.sendEmailVerification();
      setMessage("Verification email has been resent. Please check your inbox.");
    } catch (err) {
      setMessage("Failed to resend verification email. Please try again later.");
    }
  };

  return (
    <div>
      <h2>Verify Your Email</h2>
      <p>Please check your email to verify your account. You will not have full access until your email is verified.</p>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <button onClick={resendVerificationEmail}>Resend Verification Email</button>
    </div>
  );
};

export default VerifyEmail;
