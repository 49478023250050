// src/components/ForgotPassword.js
import { useState, useEffect } from 'react';
import { sendPasswordResetEmail, auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in
    if (auth.currentUser) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("A password reset link has been sent to your email.");
      setError('');
    } catch (err) {
      setError("Failed to send reset email. Please check your email address.");
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleReset}>Send Reset Link</button>
      <br />
      {isLoggedIn ? (
        <p><button onClick={() => navigate('/dashboard')}>Go Back to Dashboard</button></p>
      ) : (
        <p><Link to="/login">Remember your password? Login</Link></p>
      )}
    </div>
  );
};

export default ForgotPassword;
