// src/components/Settings.js
import { useState, useEffect } from 'react';
import { auth, signInWithEmailAndPassword } from '../firebase';
import { updatePassword, deleteUser } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const db = getFirestore(); // Initialize Firestore

const Settings = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessNiche, setBusinessNiche] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [deletePassword, setDeletePassword] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Added state for loading actions
  const navigate = useNavigate();

  const nicheOptions = [
    "E-commerce",
    "Healthcare",
    "Real Estate",
    "Automotive",
    "Technology",
    "Finance",
    "Education",
    "Other"
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFirstName(userData.firstName || '');
            setLastName(userData.lastName || '');
            setBusinessName(userData.businessName || '');
            setBusinessPhone(userData.businessPhone || '');
            setBusinessEmail(userData.businessEmail || '');
            setBusinessAddress(userData.businessAddress || '');
            setBusinessNiche(userData.businessNiche || '');
          }
        } catch (fetchError) {
          console.error("Error fetching user data: ", fetchError);
          setError("Failed to load user data.");
        }
      } else {
        navigate('/login');
      }
    };
    fetchUserData();
  }, [navigate]);

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      if (auth.currentUser) {
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userDocRef, {
          firstName,
          lastName,
          businessName,
          businessPhone,
          businessEmail,
          businessAddress,
          businessNiche
        });

        setMessage("Profile updated successfully!");
        setError('');
      } else {
        setError("User is not authenticated.");
      }
    } catch (err) {
      console.error("Error updating profile: ", err);
      setError("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match.");
      return;
    }

    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }

    try {
      setLoading(true);
      if (auth.currentUser) {
        const user = auth.currentUser;
        const email = user.email;
        await signInWithEmailAndPassword(auth, email, oldPassword);
        await updatePassword(user, newPassword);
        setMessage("Password updated successfully!");
        setError('');
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        setError("User is not authenticated.");
      }
    } catch (err) {
      console.error("Error updating password: ", err);
      setError("Failed to update password. Please check your old password and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation.toLowerCase() !== "delete") {
      setError("Please type 'delete' to confirm.");
      return;
    }

    setLoading(true); // Show loading state
    try {
      // Re-authenticate the user
      const user = auth.currentUser;
      const email = user.email;
      await signInWithEmailAndPassword(auth, email, deletePassword);

      // Delete user data from Firestore
      const userDocRef = doc(db, "users", user.uid);
      await deleteDoc(userDocRef);

      // Delete the user account
      await deleteUser(user);

      // Show success message and delay redirect
      setMessage("Your account has been successfully deleted. Redirecting to login...");
      setError('');
      setDeletePassword(''); // Clear input fields
      setDeleteConfirmation('');

      // Redirect after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      console.error("Error deleting account: ", err);
      setError("Failed to delete account. Please check your password and try again.");
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  return (
    <div>
      <h2>Settings</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <div>
        <h3>Update Profile</h3>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Business Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Business Phone"
          value={businessPhone}
          onChange={(e) => setBusinessPhone(e.target.value)}
        />
        <input
          type="email"
          placeholder="Business Email"
          value={businessEmail}
          onChange={(e) => setBusinessEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Business Address"
          value={businessAddress}
          onChange={(e) => setBusinessAddress(e.target.value)}
        />
        <select value={businessNiche} onChange={(e) => setBusinessNiche(e.target.value)}>
          <option value="">Select Business Niche</option>
          {nicheOptions.map((niche, index) => (
            <option key={index} value={niche}>{niche}</option>
          ))}
        </select>
        <button onClick={handleUpdateProfile} disabled={loading}>
          {loading ? "Updating..." : "Update Profile"}
        </button>
      </div>
      <br />
      <div>
        <h3>Change Password</h3>
        <input
          type="password"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <button onClick={handleChangePassword} disabled={loading}>
          {loading ? "Changing..." : "Change Password"}
        </button>
      </div>
      <br />
      <div>
        <h3>Delete Account</h3>
        <p>Enter your password and type "delete" to confirm account deletion.</p>
        <input
          type="password"
          placeholder="Password"
          value={deletePassword}
          onChange={(e) => setDeletePassword(e.target.value)}
        />
        <input
          type="text"
          placeholder="Type 'delete' to confirm"
          value={deleteConfirmation}
          onChange={(e) => setDeleteConfirmation(e.target.value)}
        />
        <button onClick={handleDeleteAccount} disabled={loading}>
          {loading ? "Deleting..." : "Delete Account"}
        </button>
      </div>
      <br />
      <button onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
    </div>
  );
};

export default Settings;
