import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../firebase';

const db = getFirestore();

const Dashboard = () => {
  const [userEmail, setUserEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessNiche, setBusinessNiche] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        setUserEmail(auth.currentUser.email);
        try {
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setFirstName(userData.firstName || '');
            setLastName(userData.lastName || '');
            setBusinessName(userData.businessName || '');
            setBusinessPhone(userData.businessPhone || '');
            setBusinessEmail(userData.businessEmail || '');
            setBusinessAddress(userData.businessAddress || '');
            setBusinessNiche(userData.businessNiche || '');
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/login');
      }
    };
    fetchUserData();
  }, [navigate]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
      <div>
        <h2>Welcome to the Dashboard!</h2>
        <p>You are successfully logged in.</p>
        <p>Email: {userEmail}</p>
        {firstName && <p>First Name: {firstName}</p>}
        {lastName && <p>Last Name: {lastName}</p>}
        {businessName && <p>Business Name: {businessName}</p>}
        {businessPhone && <p>Business Phone: {businessPhone}</p>}
        {businessEmail && <p>Business Email: {businessEmail}</p>}
        {businessAddress && <p>Business Address: {businessAddress}</p>}
        {businessNiche && <p>Business Niche: {businessNiche}</p>}
        <button onClick={handleSignOut}>Sign Out</button>
        <br />
        <button onClick={() => navigate('/settings')}>Settings</button>
      </div>
  );
};

export default Dashboard;
